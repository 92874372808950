import { withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "list__tabs" }
const _hoisted_2 = { class: "list__tabs--item" }
const _hoisted_3 = { class: "list__tabs--item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_parking = _resolveComponent("parking")!
  const _component_integrations = _resolveComponent("integrations")!
  const _component_app_page = _resolveComponent("app-page")!

  return (_openBlock(), _createBlock(_component_app_page, {
    name: "Инструменты",
    icon: "icon-icon-archive",
    "html-class": "tools"
  }, {
    default: _withCtx(() => [
      _createElementVNode("ul", _hoisted_1, [
        _createElementVNode("li", _hoisted_2, [
          _createElementVNode("a", {
            class: _normalizeClass([
            'list__tabs--link',
            'tools__tab--link',
            { _active: _ctx.activeTab === 'parking' },
          ]),
            href: "#",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.activeTab = 'parking'), ["prevent"]))
          }, "Парковка домена", 2)
        ]),
        _createElementVNode("li", _hoisted_3, [
          _createElementVNode("a", {
            class: _normalizeClass([
            'list__tabs--link',
            'tools__tab--link',
            { _active: _ctx.activeTab === 'integrations' },
          ]),
            href: "#",
            onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.activeTab = 'integrations'), ["prevent"]))
          }, "Мои пиксели", 2)
        ])
      ]),
      (_ctx.activeTab === 'parking')
        ? (_openBlock(), _createBlock(_component_parking, { key: 0 }))
        : _createCommentVNode("", true),
      (_ctx.activeTab === 'integrations')
        ? (_openBlock(), _createBlock(_component_integrations, { key: 1 }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}