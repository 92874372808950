
import { defineComponent } from "vue";

import AppPage from "../../components/AppPage.vue";

import Parking from "./Parking/Index.vue";
import Integrations from "./Integrations/Index.vue";

interface DataModel {
  activeTab: "parking" | "integrations";
}

export default defineComponent({
  components: {
    AppPage,
    Parking,
    Integrations,
  },
  data(): DataModel {
    return {
      activeTab: "parking",
    };
  },
});
