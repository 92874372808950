<template>
  <div class="editable-cell">
    <input
      v-if="editing"
      type="text"
      :value="value"
      @input="$emit('update', $event.target.value)"
    />
    <span
      v-else
      class="editable-cell__value"
      @click="edit"
    >
      {{ value || '-' }}
    </span>
    <div class="edit-actions" v-if="editing">
      <span class="edit-error" v-if="error">{{ error }}</span>
      <div class="edit-buttons">
        <button
            class="edit-icon-ok btn-primary"
            @click="apply"
        >
          OK
        </button>
        <button
            class="edit-icon-cancel btn-outlined"
            @click="cancel"
        >
          &times;
        </button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'EditableCell',
  props: {
    value: String,
    error: String
  },
  emits: ['save', 'update', 'cancel'],
  data() {
    return {
      editing: false,
    }
  },
  watch: {
    error(newValue) {
      this.editing = !!newValue;
    },
  },
  methods: {
    edit() {
      this.editing = true
      this.$emit('update', this.value)
    },
    apply() {
      this.$emit('save')
      this.editing = false
    },
    cancel() {
      this.$emit('cancel')
      this.editing = false
    },
  },

}
</script>

<style lang="scss" scoped>

.edit-error {
  color: red;
  text-align: start;
  font-size: 12px;
  width: calc(100% - 62px);
  white-space: normal;
}

.editable-cell__value {
  &:before {
    opacity: 0;
    content: 'Click to edit';
    position: absolute;
    top: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%);
    font-size: 85%;
    padding: 2px 3px;
    background: #666666;
    color: #fff;
    border-radius: 5px;
    z-index: 4;
    white-space: nowrap;
  }
  &:hover {
    &:before {
      opacity: 1;
      transition: opacity .2s ease;
    }
  }
}

.edit-actions {
  width: 100%;
  display: flex;
  position: absolute;
  left: 0;
  top: calc(100% + 2px);
  z-index: 3;

  .edit-buttons {
    margin-left: auto;
    flex: 0 0 64px;
  }

  .edit-buttons > button {
    width: 30px;
    display: inline-block;
    height: 30px;
    text-align: center;
    line-height: 28px;
    border-radius: 2px;
    margin-left: 2px;
  }
}
</style>