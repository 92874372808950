
import { defineComponent } from "vue";
import axios from "axios";
import Popup from "../../../components/Popup.vue";
export default defineComponent({
  components: { Popup },
  emits: ["archived", "close"],
  props: { integration: Object },
  methods: {
    async remove() {
      try {
        await axios.delete("/api/integrations/" + this.integration!.id);
        this.$emit("archived");
        ($ as any).fancybox.close();
      } catch (e) {
        console.log(e);
        this.$router.push(`/error/${(e.response && e.response.status) || 500}`);
      }
    },
  },
});
