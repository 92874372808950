
import { defineComponent } from "vue";
import axios from "axios";

import DataTable from "../../../components/DataTable.vue";
import Tooltip from "../../../components/Tooltip.vue";
import { Pagination } from "../../../modules/types";

import IntegrationPopup from './IntegrationPopup.vue';
import Archiver from './Archiver.vue';

interface DataModel {
  isIntegrationPopupShown: boolean;
  loading: boolean;
  query: Query;
  rows: any[];
  totalRows: number;
  integrationToUpdate: any;
  integrationToRemove: any;
}

interface Query {
  pagination: null | Pagination;
}

function getDefaultQuery(): Query {
  return {
    pagination: { limit: 10, offset: 0 },
  };
}

export default defineComponent({
  components: {
    DataTable,
    Tooltip,
    Archiver,
    IntegrationPopup,
  },
  data(): DataModel {
    return {
      isIntegrationPopupShown: false,
      loading: false,
      query: getDefaultQuery(),
      rows: [],
      totalRows: 0,
      integrationToUpdate: null,
      integrationToRemove: null,
    };
  },
  mounted() {
    this.$watch("query", this.load);
  },
  computed: {
    columns() {
      return [
        {
          id: "id",
          name: "ID",
          selected: true,
          sortable: false,
          htmlClass: "integrations__table--id",
        },
        {
          id: "type",
          name: "Тип",
          selected: true,
          sortable: false,
          htmlClass: "integrations__table--type",
          type: "text",
        },
        {
          id: "name",
          name: "Название",
          selected: true,
          sortable: false,
          htmlClass: "integrations__table--name",
          type: "text",
        },
        {
          id: "data",
          name: "Данные",
          selected: true,
          sortable: false,
          htmlClass: "integrations__table--data",
        },
        {
          id: "buttons",
          name: "",
          selected: true,
          freezed: true,
          sortable: false,
          htmlClass: "integrations__table--action",
        },
      ];
    },
  },
  async created() {
    this.load();
  },
  methods: {
    async load() {
      this.loading = true;

      this.rows = [];
      this.totalRows = 0;

      try {
        const { data: integrations } = await axios.get("/api/integrations", {
          params: {
            q: JSON.stringify(this.query),
          },
        });

        this.rows = integrations.rows;
        this.totalRows = integrations.total;
      } catch (e) {
        this.$router.push(`/error/${(e.response && e.response.status) || 500}`);
      }

      this.loading = false;
    },
    clear() {
      this.query = getDefaultQuery();
      this.load();
    },
    getTypeName(type: string) {
      switch (type) {
        case 'fb-pixel':
          return 'Facebook Pixel';
      }
    },
    mapDataToList(data: any) {
      const keys: any = {
        pixel_id: 'Pixel ID',
        access_token: 'Access token',
      };

      return Object.entries(data).map(([key, value]) => ({
        label: keys[key],
        value,
      }));
    }
  },
});
