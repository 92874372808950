<template>
  <popup
    title="Гайд по парковке"
    html-id="guidePopupParking"
    @close="$emit('close')"
  >
    <p>
      <strong>Парковка домена</strong> — это привязка вашего домена к новостной
      витрине, т. е. сайт витрины работает на вашем домене. Зачастую ее
      используют для таких источников трафика, как, например,
      Google/Yandex/MT/Facebook и т.п. для успешного прохождения модерации и
      снижения риска бана.
    </p>
    <p>
      Важно отметить, что не все регистраторы позволяют парковать домены 2-го
      уровня,
      <strong
        >поэтому советуем парковать домен 3-го уровня и далее подробно расскажем
        о том, как это сделать</strong
      >.
    </p>
    <ol>
      <li>
        <p>
          На странице парковки нажимаем на кнопку
          <strong>“Припарковать домен”</strong>
        </p>
        <img src="img/parking-btn.png" alt="btn" />
        <p>
          После чего заполняем поля, вписываем свой домен без слешей и
          “http://”, после чего нажимаем на кнопку "Припарковать домен" в поп
          апе.
        </p>
        <img src="img/input-img.png" alt="input" />
      </li>
      <li>
        <p>
          После добавления припаркованный домен появится в таблице на странице
          парковки.
        </p>
      </li>
      <li>
        <p>
          Чтобы
          <strong>припарковать домен 3-го уровня, в настройках DNS</strong> у
          регистратора домена
          <strong>необходимо прописать CNAME запись</strong>.
        </p>
        <p>
          <strong><span style="color: red">*</span>CNAME</strong> — каноническое
          имя для псевдонима, используется для переадресации поддомена на другой
          домен
        </p>
      </li>
      <li>
        <p>
          Для того, чтоб выбрать релевантную CNAME запись, выберите страну
          сервера и домен
        </p>
      </li>
      <li>
        <p>
          <strong>Парковка</strong> может занимать <strong>до 72 часов</strong>.
        </p>
      </li>
      <li>
        <p>
          Далее при создании/редактировании потока на выбранную новость вы
          сможете
          <strong
            >выбрать свой домен в дропдауне и получить соответствующую потоковую
            ссылку</strong
          >.
        </p>
        <img src="img/domain-input-img.png" alt="input" />
        <p>
          <strong
            ><span style="color: red">ВАЖНО</span> — не удаляйте из нее значения
            параметра “cid”, так как это - ваш уникальный идентификатор!</strong
          >
        </p>
      </li>
      <li>
        <p>
          В таблице на странице парковки представлено
          <strong>несколько разных видов статусов:</strong>
        </p>
        <ul>
          <li>
            <p>статус <strong>(Активен/Неактивен)</strong></p>
            <p>
              <i
                >обозначает статус вашего домена у нас в системе, иными словами,
                если на ваш домен заведен 1 или более активных потока — мы
                присваиваем ему <strong>Активный статус</strong>. Если же
                активных потоков нет или они были вами заархивированы — статус
                домена в системе будет <strong>“Неактивен”</strong></i
              >
            </p>
          </li>
          <li>
            <p>статус парковки <strong>(Успешно/Неуспешно)</strong></p>
            <p>
              <i
                >обозначает то, насколько успешно произведены действия на
                стороне регистратора и, соответственно, у нас в системе.</i
              >
            </p>
          </li>
        </ul>
        <p>
          <strong>Для проверки любого из статусов достаточно</strong> просто
          найти припаркованный вами домен в таблице и посмотреть на
          соответствующую колонку в ней.
        </p>
      </li>
    </ol>
    <h3>Примеры сайтов-регистраторов для покупки доменов.</h3>
    <ul>
      <li>
        <p>
          <a class="sites-link" href="https://reg.ru">Reg.ru</a>
          <a
            href="https://help.reg.ru/hc/ru/articles/4408046874769/"
            target="_blank"
            >гайд</a
          >
        </p>
      </li>
      <li>
        <p>
          <a class="sites-link" href="https://godaddy.com">Godaddy.com</a>
          <a
            href="https://ua.godaddy.com/help/dodati-zapis-cname-19236"
            target="_blank"
            >гайд</a
          >
        </p>
      </li>
      <li>
        <p>
          <a class="sites-link" href="https://beget.com">Beget.com</a>
          <a
            href="https://beget.com/ru/kb/manual/dns#redaktirovanie-zony"
            target="_blank"
            >гайд</a
          >
        </p>
      </li>
      <li>
        <p>
          <a class="sites-link" href="https://2domains.ru">2domains.ru</a>
          <a
            href="https://2domains.ru/support/domains/ip-to-domain"
            target="_blank"
            >гайд</a
          >
        </p>
      </li>
      <li>
        <p>
          <a class="sites-link" href="https://dns-master.ru">Dns-master.ru</a>
          <a href="http://dns-master.ru/simple/records.html" target="_blank"
            >гайд</a
          >
        </p>
      </li>
      <li>
        <p>
          <a class="sites-link" href="https://namecheap.co">Namecheap.co</a>
          <a
            href="https://www.namecheap.com/support/knowledgebase/article.aspx/9646/2237/how-to-create-a-cname-record-for-your-domain/"
            target="_blank"
            >гайд</a
          >
        </p>
      </li>
      <li>
        <p>
          <a class="sites-link" href="https://be.domenus.ru">Be.domenus.ru</a>
          <a
            href="https://www.domenus.ru/support/help/group/1343472/question/tipy-zapisi-a-zapis-cname-mx-txt"
            target="_blank"
            >гайд</a
          >
        </p>
      </li>
      <li>
        <p>
          <a class="sites-link" href="https://hostiq.ua">Hostiq.ua</a>
          <a
            href="https://hostiq.ua/wiki/faq-registrar-servers-dns-management/"
            target="_blank"
            >гайд</a
          >
        </p>
      </li>
    </ul>
  </popup>
</template>

<script>
import { defineComponent } from "vue";
import Popup from "../../../components/Popup";

export default defineComponent({
  components: {
    Popup,
  },
  emits: ["close"],
});
</script>

<style lang="scss" scoped>
* {
  max-width: 800px;
}

p + p {
  margin-top: 20px;
}

p {
  line-height: 24px;
}

ol,
ul {
  margin: 20px 0;
  padding-left: 20px;

  li + li {
    margin-top: 10px;
  }

  li {
    p + p {
      margin-top: 10px;
    }
  }
}

i {
  font-style: italic;
}

ul {
  list-style: disc;
}

ol {
  list-style: decimal;
}

img {
  margin: 15px 0;
  max-width: 100%;
}

a:not(.sites-link) {
  text-decoration: underline;
  color: #0d95e8;
}

.sites-link {
  color: #ff9c31;
}

h3 {
  font-weight: bold;
}
</style>
