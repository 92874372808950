import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "form popup__form",
  method: "post"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dropdown = _resolveComponent("dropdown")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_popup = _resolveComponent("popup")!

  return (_openBlock(), _createBlock(_component_popup, {
    title: !_ctx.integrationToUpdate ? 'Добавление интеграции' : 'Редактирование интеграции',
    "html-id": "integration",
    onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('close')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", _hoisted_1, [
        _createVNode(_component_dropdown, {
          label: "Тип",
          options: _ctx.integrationTypes,
          value: _ctx.integration.type,
          error: _ctx.errors?.type,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.integration.type = $event))
        }, null, 8, ["options", "value", "error"]),
        _createVNode(_component_text_input, {
          label: "Название",
          value: _ctx.integration.name,
          error: _ctx.errors?.name,
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (_ctx.integration.name = $event)),
          disabled: !_ctx.integration.type
        }, null, 8, ["value", "error", "disabled"]),
        (_ctx.integration.type === 'fb-pixel')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createVNode(_component_text_input, {
                label: "Pixel ID",
                value: _ctx.integration.data.pixel_id,
                error: _ctx.errors?.data?.pixel_id,
                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => (_ctx.integration.data.pixel_id = $event))
              }, null, 8, ["value", "error"]),
              _createVNode(_component_text_input, {
                label: "Pixel Access Token",
                value: _ctx.integration.data.access_token,
                error: _ctx.errors?.data?.access_token,
                "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => (_ctx.integration.data.access_token = $event))
              }, null, 8, ["value", "error"])
            ], 64))
          : _createCommentVNode("", true),
        _createElementVNode("button", {
          class: "btn btn-primary",
          type: "submit",
          onClick: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args)), ["prevent"]))
        }, " Сохранить ")
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}