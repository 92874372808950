
import { defineComponent } from "vue";
import Popup from "@/components/Popup.vue";
import TextInput from "@/components/TextInput.vue";
import axios from "axios";
import Dropdown from "@/components/Dropdown.vue";

export default defineComponent({
  props: {
    parking: Object,
  },
  components: {
    Popup,
    TextInput,
    Dropdown,
  },
  data() {
    return {
      geos: [],
      errors: {} as { [key: string]: string | null },
      newslab_domains: [] as any,
    };
  },
  created() {
    axios.get("/api/geos").then((response) => (this.geos = response.data));
    axios.get("/api/domains").then((response) => (this.newslab_domains = response.data));
  },
  computed: {
    newsLabDomainName() {
      return this.newslab_domains?.find((d: any) => d.geo_id == this.parking?.geo_id)
        ?.name;
    }
  },
  methods: {
    getNewsLabDomainName(geo_id: number) {
      return this.newslab_domains?.find((d: any) => d.geo_id == geo_id)
        ?.name;
    },
    emitPatch(patch: any) {
      this.$emit("update:parking", {
        ...this.parking,
        ...patch,
        newslab_domain: this.getNewsLabDomainName(patch.geo_id || this.parking?.geo_id)
      });
    },
    async saveParking() {
      try {
        this.errors = {};
        if (this.parking && !this.parking.name) return;
        const res = await axios.post("/api/domains-parked", {
          ...this.parking,
          name: this.parking?.name.toLowerCase(),
        });
        if (res.data.error && res.data.error == '23505') {
          this.errors.name = 'Домен уже существует.'
        } else {
          this.$emit("saved");
          ($ as any).fancybox.close();
        }
      } catch (e) {
        const status = e.response && e.response.status;
        if (status === 400) {
          this.errors = e.response.data;
        } else {
          this.$router.push(`/error/${status || 500}`);
        }
      }
    },
  },
});
