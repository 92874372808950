
import { defineComponent } from "vue";
import Popup from "@/components/Popup.vue";
import TextInput from "@/components/TextInput.vue";
import axios from "axios";
import Dropdown from "@/components/Dropdown.vue";

export default defineComponent({
  props: {
    integrationToUpdate: Object,
  },
  components: {
    Popup,
    TextInput,
    Dropdown,
  },
  data(props) {
    return {
      integrationTypes: [{
        id: 'fb-pixel',
        name: 'Facebook Pixel',
      }],
      integration: props.integrationToUpdate || {
        type: '',
        name: '',
        data: {},
      },
      errors: {},
    };
  },
  methods: {
    async save() {
      try {
        this.errors = {};

        if (this.integrationToUpdate) {
          await axios.put(`/api/integrations/${this.integrationToUpdate.id}`, this.integration);
        } else {
          await axios.post("/api/integrations", this.integration);
        }

        this.$emit("saved");
        ($ as any).fancybox.close();
      } catch (e) {
        const status = e.response && e.response.status;
        if (status === 400) {
          this.errors = e.response.data;
        } else {
          this.$router.push(`/error/${status || 500}`);
        }
      }
    },
  },
});
