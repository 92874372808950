
import { defineComponent } from "vue";
import Preloader from "../../../components/Preloader.vue";
import DataTable from "@/components/DataTable.vue";
import moment, { Moment } from "moment";
import { Pagination, Sorting } from "../../../modules/types";
import SubsetInput from "@/components/SubsetInput.vue";
import DateRangeInput from "@/components/DateRangeInput.vue";
import axios from "axios";
import Editor from "./Editor.vue";
import EditableCell from "@/components/EditingCell.vue";
import GuidePopup from "./GuidePopup.vue";

interface DataModel {
  loading: boolean;
  filter: Filter;
  options: {
    domains_parked: Array<Option>;
    statuses: Array<Option>;
    parking_statuses: Array<Option>;
  };
  columns: Array<Column>;
  rows: Array<any>;
  totalRows: number;
  editorFields: any;
  changed_text: any;
  errors: any;
  isGuideOpen: boolean;
}

interface MethodsModel {
  lookup(): void;
  upload(): void;
  load(): void;
  clear(): void;
  download(): void;
}

interface Filter {
  period: Moment[];
  domain_parked_ids: Array<number>;
  status_ids: Array<number | string>;
  parking_status_ids: Array<number | string>;
  pagination: null | Pagination;
  sorting: Sorting;
}

interface ComputedModel {
  query: any;
}

interface Option {
  id: number | string | boolean;
  name: string | boolean;
}

interface Column extends Option {
  selected: boolean;
  freezed?: boolean;
}

const allColumns = [
  { id: "created_at", name: "Дата", selected: true, sortable: true },
  { id: "id", name: "ID домена", selected: true, sortable: true },
  { id: "name", name: "Домен", selected: true, sortable: true, type: "text" },
  { id: "is_active", name: "Статус", selected: true, sortable: true },
  {
    id: "newslab_domain",
    name: "Домен NewsLab",
    selected: true,
    sortable: true,
    type: "text",
  },
  {
    id: "is_parking_successful",
    name: "Статус парковки",
    selected: true,
    sortable: true,
  },
  {
    id: "comment",
    name: "Комментарий",
    selected: true,
    sortable: true,
    htmlClass: "parked_comment",
    type: "text",
  },
];

function getDefaultFilter(): Filter {
  return {
    period: [moment().endOf("day").subtract(29, "days"), moment().endOf("day")],
    domain_parked_ids: [],
    status_ids: [],
    parking_status_ids: [],
    pagination: { limit: 10, offset: 0 },
    sorting: { column: "created_at", direction: "desc" },
  };
}

function getStoredColumns() {
  const json = localStorage.getItem("parking.columns");
  if (!json) {
    return allColumns;
  } else {
    const storedColumns = JSON.parse(json).reduce(
      (memo: any, c: any) => ({
        ...memo,
        [c.id]: c,
      }),
      {}
    );
    return allColumns.map((c) =>
      storedColumns[c.id]
        ? {
            ...c,
            selected: storedColumns[c.id].selected,
            freezed: storedColumns[c.id].freezed,
          }
        : c
    );
  }
}

function storeColumns(columns: Array<Column>) {
  localStorage.setItem("parking.columns", JSON.stringify(columns));
}

export default defineComponent<DataModel & ComputedModel & MethodsModel>({
  data(): DataModel {
    return {
      loading: false,
      filter: getDefaultFilter(),
      columns: getStoredColumns(),
      rows: [],
      totalRows: 0,
      options: {
        domains_parked: [],
        statuses: [],
        parking_statuses: [],
      },
      editorFields: null,
      changed_text: {
        name: "",
        comment: "",
      },
      errors: {} as { [key: string]: string | null },
      isGuideOpen: false,
    };
  },
  components: {
    Preloader,
    DataTable,
    SubsetInput,
    DateRangeInput,
    Editor,
    EditableCell,
    GuidePopup,
  },
  watch: {
    columns(newColumns: Array<Column>) {
      storeColumns(newColumns);
    },
  },
  computed: {
    query() {
      return { q: JSON.stringify(this.filter) };
    },
  },
  async created() {
    this.options.statuses = [
      { id: 1, name: "Активен" },
      { id: 0, name: "Неактивен" },
    ];
    this.options.parking_statuses = [
      { id: 1, name: "Успешно" },
      { id: 0, name: "Неуспешно" },
    ];
    this.load();
  },
  mounted() {
    this.$watch("filter.sorting", this.load);
    this.$watch("filter.pagination", this.load);
  },
  methods: {
    cancelUpdate() {
      this.load();
    },
    updateCellName(value: string) {
      this.changed_text.name = value;
    },
    updateCellComment(value: string) {
      this.changed_text.comment = value;
    },
    async lookup(query = "") {
      const { data } = await axios.get("/api/domains-parked/lookup", {
        params: { q: JSON.stringify({ query }) },
      });
      this.options.domains_parked = data;
    },
    async load() {
      this.errors = {};
      this.loading = true;
      try {
        const { data } = await axios.get("/api/domains-parked", {
          params: this.query,
        });
        this.rows = data.rows;
        this.totalRows = data.total;

        this.lookup();
      } catch (e) {
        this.$router.push(`/error/${(e.response && e.response.status) || 500}`);
      }
      this.loading = false;
    },
    upload() {
      this.load();
      this.lookup();
    },
    clear() {
      this.filter = getDefaultFilter();
      this.load();
    },
    download() {
      window.open(
        `/api/domains-parked/download?q=${encodeURIComponent(this.query.q)}`,
        "_blank"
      );
    },
    openEditor() {
      this.editorFields = {
        name: "",
        geo_id: null,
        newslab_domain: "",
        is_parking_successful: false,
      };
    },
    formatDate(d: string): string {
      return moment(d).format("DD.MM.YYYY");
    },
    async saveChange(id: number, key: string) {
      this.errors = {};
      let item = this.rows.find((item) => item.id == id);
      if (key === "name" && item.comment === null) item.comment = "";
      item[key] = this.changed_text[key];
      try {
        const res = await axios.post(`/api/domains-parked/update`, {
          ...item,
          name: item.name.toLowerCase(),
        });
        if (res.data.error && res.data.error == "23505") {
          this.errors = { name: "Домен уже существует.", id: id };
        } else {
          this.changed_text.name = "";
          this.changed_text.comment = "";
          this.load();
        }
      } catch (e) {
        const status = e.response && e.response.status;
        if (status === 400) {
          this.errors = { ...e.response.data, id: id };
        } else {
          this.$router.push(`/error/${status || 500}`);
        }
      }
    },
  },
});
